import React from 'react';
import { TGetUserTenants } from '@hub/contracts';
import { Avatar, Divider } from '@hub/ui';

interface ITenantEntry {
  tenant: TGetUserTenants;
  onTenantPress: (tenantId: string) => void;
}

export const TenantEntry = ({ tenant, onTenantPress }: ITenantEntry) => {
  return (
    <div>
      <button
        className="cursor-pointer w-full hover:bg-neutral-50 p-2 rounded-xl"
        onClick={() => onTenantPress(tenant.id)}
      >
        <div className="flex items-center gap-8">
          <Avatar contentType="text" name={tenant.name} src={'/avatar-not-logged.png'} />
          <p className="font-medium">{tenant.name}</p>
        </div>
      </button>
      <Divider type="horizontal" />
    </div>
  );
};
