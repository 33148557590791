import { useEffect } from 'react';
import { t } from '@lingui/macro';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { getCookie } from 'cookies-next';
import { useAuth } from '@hub/cognito-auth';
import { TUpdateTenant } from '@hub/contracts';
import { createHttpClient } from '@hub/http-client';
import { getEnvVariables } from '@hub/next-utils';
import { queryKeys } from '../../utils';
import { TenantApi } from '../Api/Tenant';

export const useTenants = () => {
  const { signOut } = useAuth();

  const tenantsApi = new TenantApi(createHttpClient({ url: getEnvVariables().PAGE_URL }));

  const useGetTenantsList = () => {
    const { data, isLoading, error } = useQuery(
      queryKeys.tenants.query('').queryKey,
      async () => await tenantsApi.getTenantsForUser(),
      {
        // Tenants list cannot be cached, because then after logging out and logging to different account we might get cached tenants and the logic based on wrong tanants will be invoked
        //  This is visible when logging into account with only one tenant, then logging out, then logging into account with multiple tenants. The app thikns there is only one tenant ( query is fetching, but cached one tenant is used )
        cacheTime: 0,
      },
    );

    return {
      tenants: data,
      loading: isLoading,
      error,
    };
  };

  const useCurrentTenant = () => {
    const currentTenantId = getCookie('x-tenant-id');

    const { tenants, loading, error } = useGetTenantsList();

    useEffect(() => {
      if (!currentTenantId) signOut();
    }, [currentTenantId]);

    const currentTenant = tenants?.find(tenant => tenant.id === currentTenantId) || null;

    return {
      currentTenant,
      loading,
      error: currentTenant ? error : new Error(t`Oops. We cannot fetch data for your organization`),
    };
  };

  const useUpdateTenant = (tenantId: string) => {
    const queryClient = useQueryClient();

    const {
      mutate: updateTenant,
      isLoading,
      error,
    } = useMutation<void, Error, TUpdateTenant>(
      updateDto => tenantsApi.updateTenant(tenantId, updateDto),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeys.tenants.query('').queryKey);
        },
      },
    );

    return {
      updateTenant,
      isLoading,
      error,
    };
  };

  const useSetUserTenant = (tenantId: string, onSuccess: () => Promise<void>) => {
    // request to own next serv to save tenant id in server cookies
    axios
      .post(`${getEnvVariables().PAGE_URL}/api/set-tenant/`, {
        tenantId,
      })
      .then(async () => {
        return await onSuccess();
      });
  };

  return {
    useGetTenantsList,
    useCurrentTenant,
    useUpdateTenant,
    useSetUserTenant,
  };
};
