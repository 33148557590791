import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Button, Spinner } from '@elpassion/taco';
import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import { useAuth } from '@hub/cognito-auth';
import { deleteAllCookies } from '@hub/next-utils';
import { ErrorIcon, Modal } from '@hub/ui';
import { routes } from '../../../utils';
import { TenantEntry } from '../../Tenants/components/TenantEntry';
import { useTenants } from '../hooks';

export const ChooseTenantModal = () => {
  const [isLoadingTenantWithRedirect, setIsLoadingTenantWithRedirect] = useState<boolean>(false);

  const router = useRouter();
  const { setCurrentAuthenticatedUser, cognitoApi } = useAuth();

  const { useSetUserTenant, useGetTenantsList } = useTenants();
  const { tenants, loading } = useGetTenantsList();

  const onTenantSelected = async () => {
    setIsLoadingTenantWithRedirect(true);
    await setCurrentAuthenticatedUser();
    if (router.query?.redirect) {
      await router.push(
        `/${router.query.redirect}${router.query?.token ? `?token=${router.query.token}` : ''}`,
      );
    } else {
      await router.push(routes.timeReports);
    }
  };

  const onTenantPress = (tenantId: string) => {
    useSetUserTenant(tenantId, onTenantSelected);
  };

  const signOut = () => {
    deleteAllCookies();
    cognitoApi
      ?.signOut()
      .then(() => {
        localStorage.clear();
        axios.post('/api/logout');
        router.reload();
      })
      .catch(() => console.error('Sth went wrong during logout'));
  };

  useEffect(() => {
    if (tenants && tenants.length === 1) {
      useSetUserTenant(tenants[0].id, onTenantSelected);
    } else if (tenants && tenants.length === 0) {
      // throw new Error('Missing tenants! User must have at least one tenant!');
    }

    return () => {
      setIsLoadingTenantWithRedirect(false);
    };
  }, [tenants, loading]);

  // Do not show modal until tenants are loaded
  if (!tenants) return null;
  // Do not show modal when only one tenant -> it will be selected automatically
  if (tenants.length === 1) return null;

  // Show error page if there is no tenants
  if (tenants && tenants.length === 0) {
    return (
      <div className="flex h-screen fixed top-0 bottom-0 left-0 right-0 bg-white z-10">
        <div className="m-auto text-center">
          <div className="inline-block">
            <ErrorIcon />
          </div>
          <h1 className="text-sm font-medium text-label-base mt-4 mb-8">
            <Trans>
              Something is wrong with this account.
              <br />
              Please contact your organization's administrator or our support team at{' '}
              <a href="mailto:contact@el-chrono.com">contact@el-chrono.com</a>.
            </Trans>
          </h1>
          <Button variant="filled" size="md" className="m-auto" onClick={signOut}>
            <span className="text-base font-medium">{t`Log out`}</span>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Modal
      isOpen={true}
      onClose={() => {
        /* do nothing */
      }}
      testId="pick-tenant-modal"
      className="lg:w-1/3 px-6"
    >
      <h2 className="text-3xl font-medium mb-2 text-neutral-800 text-center 2xl:text-left">
        <Trans>Choose your organization</Trans>
      </h2>

      {isLoadingTenantWithRedirect && (
        <div className="w-full flex items-center justify-center h-20">
          <Spinner size="sm" />
        </div>
      )}

      {!isLoadingTenantWithRedirect &&
        tenants.map(tenant => (
          <TenantEntry key={tenant.id} onTenantPress={onTenantPress} tenant={tenant} />
        ))}
    </Modal>
  );
};
