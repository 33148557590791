import { TGetUserTenants, TUpdateTenant } from '@hub/contracts';
import { IHttpClient } from '@hub/http-client';

interface ITenantApi {
  getTenantsForUser: () => Promise<TGetUserTenants[]>;
  updateTenant: (tenantId: string, updateDto: TUpdateTenant) => Promise<void>;
}

export class TenantApi implements ITenantApi {
  private baseUrl = '/api/tenants';

  private client: IHttpClient;

  constructor(client: IHttpClient) {
    this.client = client;
  }

  async getTenantsForUser() {
    return this.client.get<TGetUserTenants[]>(`${this.baseUrl}/`);
  }

  async updateTenant(tenantId: string, updateDto: TUpdateTenant): Promise<void> {
    await this.client.patch<TUpdateTenant, void>(`${this.baseUrl}/${tenantId}`, updateDto);
  }
}
