import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory';
import { BillingPeriodId } from '@hub/contracts';
import { ListApprovalPeriodUsersFilters } from '../modules/Api/Approvals';
import { TProjectListFilters } from '../modules/Project';
import { IAllTimeReportListFilters } from '../modules/TimeReport';
import { DateRangeType } from '../modules/TimeReport/useDateRangePicker';
import { PaginateQueryWithoutPath } from './types';

const usersKeys = createQueryKeys('users', {
  all: null,
  me: null,
  query: (query: string) => [query],
  paginateQuery: (
    paginateQuery: PaginateQueryWithoutPath,
    filters?: { showActive: boolean; showArchived: boolean },
  ) => [paginateQuery.search || '', paginateQuery.page, filters?.showActive, filters?.showArchived],
  currentUser: (id: string) => [id],
  currentUserAbilities: (id: string) => [id],
});

const clientsKeys = createQueryKeys('clients', {
  all: null,
  query: (query: string) => [query],
  paginateQuery: (
    paginateQuery: PaginateQueryWithoutPath,
    filters?: { showActive: boolean; showArchived: boolean },
  ) => [paginateQuery.search || '', paginateQuery.page, filters?.showActive, filters?.showArchived],
});

const projectsKeys = createQueryKeys('projects', {
  all: null,
  allMy: null,
  query: (query: string) => [query],
  paginateQuery: (paginateQuery: PaginateQueryWithoutPath, filters?: TProjectListFilters) => [
    paginateQuery.search || '',
    paginateQuery.page,
    filters?.showActive,
    filters?.showArchived,
    filters?.showRetainer,
    filters?.clientId || undefined,
  ],
  favourites: null,
});

const timeReportsKeys = createQueryKeys('timeReports', {
  all: null,
  allStatistics: null,
  allMy: null,
  query: (query: string) => [query],
  dateRange: (dateRange: DateRangeType) => [
    dateRange.firstDay ? dateRange.firstDay.toDateString() : '',
    dateRange.lastDay ? dateRange.lastDay.toDateString() : '',
  ],
  withFilters: ({
    dateRange,
    employeeId,
    projectId,
    clientId,
    type,
  }: IAllTimeReportListFilters) => [
    dateRange.firstDay ? dateRange.firstDay.toDateString() : '',
    dateRange.lastDay ? dateRange.lastDay.toDateString() : '',
    employeeId || '',
    projectId || '',
    clientId || '',
    type || '',
  ],
  statisticsWithFilters: ({
    dateRange,
    employeeId,
    projectId,
    type,
  }: IAllTimeReportListFilters) => [
    dateRange.firstDay ? dateRange.firstDay.toDateString() : '',
    dateRange.lastDay ? dateRange.lastDay.toDateString() : '',
    employeeId || '',
    projectId || '',
    type || '',
  ],
  userProjectsStatisticsWithFilters: ({
    dateRange,
    employeeId,
    projectId,
    type,
  }: IAllTimeReportListFilters) => [
    dateRange.firstDay,
    dateRange.lastDay,
    employeeId || undefined,
    projectId || undefined,
    type || undefined,
  ],
  projectsUsersStatisticsWithFilters: ({
    dateRange,
    employeeId,
    projectId,
    type,
  }: IAllTimeReportListFilters) => [
    dateRange.firstDay,
    dateRange.lastDay,
    employeeId || undefined,
    projectId || undefined,
    type || undefined,
  ],
  reportTemplates: null,
});

const holidaysKeys = createQueryKeys('holidays', {
  all: null,
  dateRange: (dateRange: DateRangeType) => [
    dateRange.firstDay ? dateRange.firstDay.toDateString() : '',
    dateRange.lastDay ? dateRange.lastDay.toDateString() : '',
  ],
});

const statisticsKeys = createQueryKeys('statistics', {
  all: null,
  dateRange: (dateRange: DateRangeType) => [dateRange.firstDay, dateRange.lastDay],
});

const externalLinksKeys = createQueryKeys('externalLinks', {
  all: null,
  query: (query: string) => [query],
});

const slackTasksKeys = createQueryKeys('slackTasks', {
  all: null,
  query: (query: string) => [query],
});

const slackMessage = createQueryKeys('slackMessage', {
  all: null,
  query: (query: string) => [query],
});

const slackIntegration = createQueryKeys('slackIntegration', {
  all: null,
  workspace: () => ['workspace'],
  subscriptionData: () => ['subscription'],
  chatBotHistory: (paginateQuery?: PaginateQueryWithoutPath) => {
    if (paginateQuery) {
      return ['chat-bot', 'history', paginateQuery];
    }
    return ['chat-bot', 'history'];
  },
});

const tenantsKeys = createQueryKeys('tenants', {
  all: null,
  query: (query: string) => [query],
});

const approvalsKeys = createQueryKeys('approvals', {
  list: (paginateQuery?: PaginateQueryWithoutPath) => {
    if (!paginateQuery) return ['list'];

    return ['list', paginateQuery];
  },
  users: (id: BillingPeriodId, filters?: ListApprovalPeriodUsersFilters) => {
    if (filters) {
      return [id, 'employees', filters];
    }

    return [id, 'employees'];
  },
});

const subscriptionsKeys = createQueryKeys('subscription', {
  all: null,
  query: (query: string) => [query],
  preview: (quantity: number) => [quantity],
});

const productsKeys = createQueryKeys('products', {
  all: null,
  query: (query: string) => [query],
});

const tenantsSettingsKeys = createQueryKeys('tenantsSettings', {
  all: null,
  query: (query: string) => [query],
  currentSettings: (id: string) => [id],
});

const formsManagementKeys = createQueryKeys('formsManagement', {
  all: null,
  query: (query: string) => [query],
});

const publicHolidaysKeys = createQueryKeys('publicHolidays', {
  all: null,
  query: (query: string) => [query],
  calendarDays: (calendarId: string) => ['calendarDays', calendarId],
  attachedUsers: (calendarId: string) => ['attachedUsers', calendarId],
  calendarUsers: (calendarId: string) => ['calendarUsers', calendarId],
});

const holidayCalendarsKeys = createQueryKeys('holidayCalendars', {
  all: null,
  query: (query: string) => [query],
});

const timeEntryActivitiesKeys = createQueryKeys('timeEntryActivities', {
  all: null,
  query: (query: string) => [query],
});

export const queryKeys = mergeQueryKeys(
  usersKeys,
  clientsKeys,
  projectsKeys,
  timeReportsKeys,
  holidaysKeys,
  statisticsKeys,
  externalLinksKeys,
  slackTasksKeys,
  slackMessage,
  tenantsKeys,
  approvalsKeys,
  subscriptionsKeys,
  productsKeys,
  tenantsSettingsKeys,
  slackIntegration,
  formsManagementKeys,
  publicHolidaysKeys,
  holidayCalendarsKeys,
  timeEntryActivitiesKeys,
);
